<script setup lang="ts">
import VBlockLoader from "@magnit/core/src/components/VLoader/VBlockLoader.vue";
import VDivider from "@magnit/core/src/components/VDivider/VDivider.vue";
import type { IResponsiveOnly } from "@magnit/core/types";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import VProductPageYaSlot from "~/components/VProductPage/VProductPageYaSlot.vue";
import VMainBanner from "~/containers/VMainBanner.vue";
import { useContentStore } from "~/store/content";
import VSpecialOffers from "~/containers/VSpecialOffers/VSpecialOffers.vue";
import VTicker from "~/containers/VTicker.vue";
import VNovelties from "~/containers/VNovelties.vue";
import type { ISwiperOptions } from "~/typings/components/appSlider";

const contentStore = useContentStore();
await contentStore.requestMainPage();
const breakpoints: IResponsiveOnly<ISwiperOptions> = {
  xs: {
    slidesPerView: "auto",
    spaceBetween: 8,
    pagination: {
      enabled: true,
    },
    slidesOffsetAfter: 12,
    slidesOffsetBefore: 12,
  },
  s: {
    slidesPerView: "auto",
    spaceBetween: 12,
    pagination: {
      enabled: true,
    },
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
  },
  m: {
    slidesPerView: "auto",
    spaceBetween: 20,
    pagination: {
      enabled: true,
    },
    slidesOffsetAfter: 32,
    slidesOffsetBefore: 32,
  },
  ml: {
    slidesPerView: "auto",
    spaceBetween: 20,
    pagination: {
      enabled: true,
    },
    slidesOffsetAfter: 250,
    slidesOffsetBefore: 32,
  },
  l: {
    slidesPerView: "auto",
    spaceBetween: 24,
    pagination: {
      enabled: true,
    },
    navigation: true,
    slidesOffsetAfter: 300,
    slidesOffsetBefore: 0,
  },
  xl: {
    slidesPerView: 3,
    spaceBetween: 24,
    pagination: {
      enabled: true,
    },
    navigation: true,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
};
</script>

<template>
  <div class="product-layout">
    <NuxtLayout name="default">
      <div>
        <slot />
      </div>
      <VBlockLoader :show="contentStore.status.mainPage === 'pending'" />
      <template v-if="contentStore.status.mainPage !== 'pending'">
        <VWrapperLocal>
          <VDivider class="product-layout__divider" />
        </VWrapperLocal>
        <VMainBanner :breakpoints="breakpoints" />
        <VSpecialOffers />
        <VTicker />
        <VNovelties />
        <VWrapperLocal>
          <VProductPageYaSlot />
        </VWrapperLocal>
      </template>
    </NuxtLayout>
  </div>
</template>

<style scoped lang="postcss">
.product-layout {
  &__divider {
    margin-top: var(--pl-unit-x5);
    margin-bottom: var(--pl-unit-x10);

    @media (--pl-viewport-from-l) {
      margin-top: 84px;
    }
  }

  &:deep(.ticker-wrapper) {
    margin-top: 64px;
    margin-bottom: 52px;

    @media (--pl-viewport-from-ml) {
      margin-top: 64px;
      margin-bottom: 52px;
    }

    @media (--pl-viewport-from-l) {
      margin-top: 64px;
      margin-bottom: 80px;
    }
  }
}
</style>
